// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("channels")
var wrap = require("word-wrap")

require('chartkick')
require('chart.js')


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


// Ransack Filter
$(document).on("turbolinks:load", () => {
  var covidTestReceivedAtRange = $('#q_specimen_received_at_between_dates')

  // GuardClause
  if(covidTestReceivedAtRange.length == 0) {
    return;
  }

  covidTestReceivedAtRange.daterangepicker({
    autoUpdateInput: false,
    ranges: {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    },
    alwaysShowCalendars: true
  })

  covidTestReceivedAtRange.on('apply.daterangepicker', (ev, picker) => {
    covidTestReceivedAtRange.val(
      picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY')
    )
  })
})

// Ransack Filter
$(document).on("turbolinks:load", () => {
  var covidTestReleasedAtRange = $('#q_released_at_between_dates')

  // GuardClause
  if(covidTestReleasedAtRange.length == 0) {
    return;
  }

  covidTestReleasedAtRange.daterangepicker({
    autoUpdateInput: false,
    ranges: {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    },
    alwaysShowCalendars: true
  })

  covidTestReleasedAtRange.on('apply.daterangepicker', (ev, picker) => {
    covidTestReleasedAtRange.val(
      picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY')
    )
  })
})

$(document).on("turbolinks:load", () => {
  $('.auto-select2').select2({
    theme: 'bootstrap4',
  })

  $('.organizations-select2').select2({
    theme: 'bootstrap4',
    ajax: {
      url: '/organizations.json',
      dataType: 'json',
      data: (params) => {
        return {
          q: {
            name_cont: params.term
          }
        }
      },
      processResults: (data) => {
        var options = $.map(data, (item) => {
          return {
            id: item.id,
            text: item.name
          }
        });

        return {
          results: options
        }
      }
    }
  })

  $('#link_covid_test_request_covid_test_request_id').select2({
    theme: "bootstrap4",
    ajax: {
      url: '/covid_test_requests.json',
      dataType: 'json',
      data: (params) => {
        organizationId = $('#link_covid_test_request_covid_test_request_id').data('organizationId')
        return {
          q: {
            patient_full_name_cont: params.term,
            organization_id_eq: organizationId
          }
        }
      },
      processResults: (data) => {
        var options = $.map(data, (item) => {
          return {
            id: item.id,
            text: item.patient_full_name
          }
        });

        return {
          results: options
        }
      }
    }
  })
})

$(document).on("turbolinks:before-cache", () => {
  $('.auto-select2').select2("destroy")
  $('.organizations-select2').select2("destroy")
})

$(document).on("turbolinks:load", () => {
  var collectionDateTimeInput = $(
    [
      '#covid_test_specimen_collected_at',
      '#covid_test_specimen_collection_specimen_collected_at',
      '#change_covid_test_released_timestamp_released_at'
    ].join(', ')
  )

  if(collectionDateTimeInput.length > 0) {
    collectionDateTimeInput.daterangepicker({
      timePicker: true,
      singleDatePicker: true,
      locale: {
        format: 'YYYY-MM-DD HH:mm'
      }
    })
  }

  var receivedDateTimeInput = $('#covid_test_specimen_received_at')
  if(receivedDateTimeInput.length > 0) {
    receivedDateTimeInput.daterangepicker({
      timePicker: true,
      singleDatePicker: true,
      locale: {
        format: 'YYYY-MM-DD HH:mm'
      }
    })
  }

  var transactionReceiptReceivedDateTimeInput = $('#transaction_receipt_received_at')
  if(transactionReceiptReceivedDateTimeInput.length > 0) {
    transactionReceiptReceivedDateTimeInput.daterangepicker({
      timePicker: true,
      singleDatePicker: true,
      locale: {
        format: 'YYYY-MM-DD HH:mm'
      }
    })
  }
})

$(document).on("turbolinks:load", () => {
  var transactionReceiptPrint = $('#transaction-receipt-print')
  if(transactionReceiptPrint.length == 0) {
    return;
  }

  setTimeout(function() {
    window.print();
    window.history.back();
  }, 500);
})

$(document).on("turbolinks:load", () => {
  var covidTestConfirmationPrint = $('#covid-test-print-confirmation')
  if(covidTestConfirmationPrint.length == 0) {
    return;
  }

  setTimeout(function() {
    window.print();
    window.history.back();
  }, 500);
})

$(document).on("turbolinks:load", () => {
  var historyExposureContacts = $('tbody.history-exposure-contacts')

  $('.add-history-exposure-contact').on("click", (event) => {
    event.preventDefault()

    var template = historyExposureContacts.find('tr').last().clone()
    template.find('input').val("");
    historyExposureContacts.append(template);
  });

  var additionalCloseContacts = $('tbody.additional-close-contacts')

  $('.add-additional-close-contact').on('click', (event) => {
    event.preventDefault()

    var template = additionalCloseContacts.find('tr').last().clone()
    template.find('input').val("");
    additionalCloseContacts.append(template);
  });

  caseDatesSelector = [
    '#case_interview_date',
    '#case_departure_date',
    '#case_arrival_date',
    '#case_history_exposure_contact_date',
    '#case_history_exposure_to_place_date',
    '#case_admission_date',
    '#case_chest_xray_date',
    '#case_previous_covid_consultation_date',
    '#case_previous_covid_case_admission_date',
    '#case_disposition_discharge_date',
    '#case_patient_previous_positive_covid_date',
    '#case_outcome_recovery_date',
    '#case_outcome_death_date',
    '#case_history_exposure_to_place_health_facility_date',
    '#case_history_exposure_to_place_closed_setting_date',
    '#case_history_exposure_to_place_market_date',
    '#case_history_exposure_to_place_home_date',
    '#case_history_exposure_to_place_international_travel_date',
    '#case_history_exposure_to_place_school_date',
    '#case_history_exposure_to_place_transportation_date',
    '#case_history_exposure_to_place_work_place_date',
    '#case_history_exposure_to_place_local_travel_date',
    '#case_history_exposure_to_place_social_gathering_date',
    '#case_history_covid_local_departure_date',
    '#case_history_covid_local_arrival_date',
    '#covid_test_case_attributes_interview_date',
    '#covid_test_case_attributes_departure_date',
    '#covid_test_case_attributes_arrival_date',
    '#covid_test_case_attributes_history_exposure_contact_date',
    '#covid_test_case_attributes_history_exposure_to_place_date',
    '#covid_test_case_attributes_admission_date',
    '#covid_test_case_attributes_chest_xray_date',
    '#covid_test_case_attributes_previous_covid_consultation_date',
    '#covid_test_case_attributes_previous_covid_case_admission_date',
    '#covid_test_case_attributes_disposition_discharge_date',
    '#covid_test_case_attributes_patient_previous_positive_covid_date',
    '#covid_test_case_attributes_outcome_recovery_date',
    '#covid_test_case_attributes_outcome_death_date',
    '#covid_test_case_attributes_history_exposure_to_place_health_facility_date',
    '#covid_test_case_attributes_history_exposure_to_place_closed_setting_date',
    '#covid_test_case_attributes_history_exposure_to_place_market_date',
    '#covid_test_case_attributes_history_exposure_to_place_home_date',
    '#covid_test_case_attributes_history_exposure_to_place_international_travel_date',
    '#covid_test_case_attributes_history_exposure_to_place_school_date',
    '#covid_test_case_attributes_history_exposure_to_place_transportation_date',
    '#covid_test_case_attributes_history_exposure_to_place_work_place_date',
    '#covid_test_case_attributes_history_exposure_to_place_local_travel_date',
    '#covid_test_case_attributes_history_exposure_to_place_social_gathering_date',
    '#covid_test_case_attributes_history_covid_local_departure_date',
    '#covid_test_case_attributes_history_covid_local_arrival_date',
  ].join(',')

  $(caseDatesSelector).daterangepicker({
    singleDatePicker: true,
    autoUpdateInput: false,
    autoApply: true,
    locale: {
      format: 'YYYY-MM-DD'
    }
  })

  $(caseDatesSelector).on('apply.daterangepicker', (ev, picker) => {
    $(ev.target).val(picker.startDate.format('YYYY-MM-DD'))
  })

  /*
   * THIS WHOLE THING DOESNT WORK! FIND A BETTER DATE SELECTOR!!!
   * 
   *
  var caseTimestampsSelector = [
    '#case_disposition_admitted_in_hospital_timestamp',
    '#case_disposition_admitted_in_quarantine_facility_timestamp',
    '#case_disposition_home_quarantine_timestamp',
    '#covid_test_case_attributes_disposition_admitted_in_hospital_timestamp',
    '#covid_test_case_attributes_disposition_admitted_in_quarantine_facility_timestamp',
    '#covid_test_case_attributes_disposition_home_quarantine_timestamp',
  ].join(',')

  $(caseTimestampsSelector).daterangepicker({
    timePicker: true,
    singleDatePicker: true,
    autoUpdateInput: false,
    //autoApply: true,
    local: {
      format: 'YYYY-MM-DD HH:mm'
    }
  })

  $(caseTimestampsSelector).on('apply.daterangepicker', (ev, picker) => {
    $(ev.target).val(picker.startDate.format('YYYY-MM-DD HH:mm'))
  })
  */

})

$(document).on('turbolinks:load', () => {
  $('#airport_validation_or_number, #airport_validation_owwa_info').on('keyup', (event) => {
    var value = $(event.target).val()
    $('form.airport_validation input[type=submit]').attr(
      "disabled",
      (value.trim().length == 0)
    )
  })
})

$(document).on('turbolinks:load', () => {
  var dataFieldChangesHandler = (event) => {
    var orNumber = $('#pal_international_departures_or_number').val()
    var collectionSite = $('#pal_international_departures_specimen_collection_site').val()
    var collectionDateTime = $('#pal_international_departures_specimen_collected_at').val()

    var disabled =
      (orNumber.trim().length == 0) ||
      (collectionSite.trim().length == 0) ||
      (collectionDateTime.trim().length == 0)

    $('form.pal_international_departures input[type=submit]').attr(
      "disabled",
      disabled
    )
  }

  $('#pal_international_departures_or_number').on('keyup', dataFieldChangesHandler)
  $('#pal_international_departures_specimen_collected_at').on('keyup', dataFieldChangesHandler)
  $('#pal_international_departures_specimen_collection_site').on('change', dataFieldChangesHandler)
})

$(document).on('turbolinks:load', () => {
  var dataFieldChangesHandler = (event) => {
    var orNumber = $('#pal_domestic_departures_or_number').val()
    var collectionSite = $('#pal_domestic_departures_specimen_collection_site').val()
    var collectionDateTime = $('#pal_domestic_departures_specimen_collected_at').val()

    var disabled =
      (orNumber.trim().length == 0) ||
      (collectionSite.trim().length == 0) ||
      (collectionDateTime.trim().length == 0)

    $('form.pal_domestic_departures input[type=submit]').attr(
      "disabled",
      disabled
    )
  }

  $('#pal_domestic_departures_or_number').on('keyup', dataFieldChangesHandler)
  $('#pal_domestic_departures_specimen_collected_at').on('keyup', dataFieldChangesHandler)
  $('#pal_domestic_departures_specimen_collection_site').on('change', dataFieldChangesHandler)
})

$(document).on("click", ".print-barcode", (event) => {
  event.preventDefault()

  var data = $(event.target).data()

  JSPM.JSPrintManager.auto_reconnect = true;
  JSPM.JSPrintManager.start();
  JSPM.JSPrintManager.WS.onStatusChanged = () => {
    if(JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Open) {
      var job = new JSPM.ClientPrintJob()
      job.clientPrinter = new JSPM.InstalledPrinter("Godex EZ120")

      var patientFullNames = wrap(data.patientFullName, { width: 23, indent: '', trim: true }).split('\n').slice(0, 2)
      var yPosition = 56
      var nameHeight = 22

      commands = []

      commands.push("^W90")
      commands.push("^Q23,0,0")
      commands.push("^E40")
      commands.push("^L")

      commands.push("BQ,12,0,1,5,30,0,0," + data.barcode)
      commands.push("AB,12,30,1,1,0,0," + data.specimenNumber)
      $.each(patientFullNames, (index, value) => {
        commands.push("AA,12," + yPosition + ",1,1,0,0," + value)
        yPosition += nameHeight
      })
      commands.push("AA,20," + yPosition + ",1,1,0,0," + data.patientBirthDate)
      if(data.priority == 'high') {
        commands.push("AB,130," + (yPosition - 4) + ",1,1,4,0I, HIGH ")
      }

      commands.push("BQ,252,0,1,5,30,0,0," + data.barcode)
      commands.push("AB,252,30,1,1,0,0," + data.specimenNumber)
      yPosition = 56
      $.each(patientFullNames, (index, value) => {
        commands.push("AA,252," + yPosition + ",1,1,0,0," + value)
        yPosition += nameHeight
      })
      commands.push("AA,260," + yPosition + ",1,1,0,0," + data.patientBirthDate)
      if(data.priority == 'high') {
        commands.push("AB,370," + (yPosition - 4) + ",1,1,4,0I, HIGH ")
      }

      commands.push("BQ,492,0,1,5,30,0,0," + data.barcode)
      commands.push("AB,492,30,1,1,0,0," + data.specimenNumber)
      yPosition = 56
      $.each(patientFullNames, (index, value) => {
        commands.push("AA,492," + yPosition + ",1,1,0,0," + value)
        yPosition += nameHeight
      })
      commands.push("AA,500," + yPosition + ",1,1,0,0," + data.patientBirthDate)
      if(data.priority == 'high') {
        commands.push("AB,610," + (yPosition - 4) + ",1,1,4,0I, HIGH ")
      }

      commands.push("E")
      commands.push("")

      job.printerCommands = commands.join("\n")
      job.sendToClient()
    }
  }
})

$(document).on("turbolinks:load", () => {
	var barangayMagic = $('.select2-barangay-magic')
  barangayMagic.select2({
    theme: 'bootstrap4',
    minimumInputLength: 3,
    ajax: {
      url: '/clients/barangays.json',
      data: (params) => {
        var query = {
          q: {
            search_barangay: params.term
          },
          page: params.page || 1
        }

        return query;
      },
      processResults: (data) => {
        var selectOption = []
        $.each(data, (index, elem) => {
          selectOption.push({
            id: elem.id,
            text: [elem.name, elem.city, elem.province].join(', '),
            barangay: elem.name,
            city: elem.city,
            province: elem.province
          })
        });

        return {
          results: selectOption,
          pagination: {
            more: (selectOption.length == 100)
          }
        }
      }
    }
  })

  barangayMagic.on('select2:select', (e) => {
    var addressData = e.params.data;

    $('#covid_test_patient_barangay').val(
      addressData.barangay
    );

    $('#covid_test_patient_city').val(
      addressData.city
    );

    $('#covid_test_patient_province').val(
      addressData.province
    )

  });
});

$(document).on("turbolinks:load", () => {
  var croppie;

  $('#patient-photo-camera').on('click', (event) => {
    $('#patient-photo-selector').click();
  })

  $('#patient-photo-save').on('click', (event) => {
    $('#patient-photo-save').attr('disabled', true);

    croppie.croppie('result', {
      type: 'base64',
      size: 'viewport',
      format: 'jpeg'
    }).then((data) => {
      $('#covid_test_patient_photo').val(data)
      $('#covid-test-patient-photo-form').submit()
    })
  })

  $('#patient-photo-selector').on('change', (event) => {
    var selectedFile = event.target.files[0];
    var reader = new FileReader();

    reader.onload = (event) => {
      if(croppie) {
        croppie.croppie('destroy');
      }

      $('.patient-photo-editor').attr('src', event.target.result);
      croppie = $('.patient-photo-editor').croppie({
        enableOrientation: true,
        viewport: {
          width: 345,
          height: 345
        },
        boundary: {
          width: 460,
          height: 460
        }
      });

      $('#patient-photo-save').show();
    }

    reader.readAsDataURL(selectedFile);
  })
});

$(document).on('turbolinks:load', () => {
  var pesoPayPaymentForm = $('#pesoPayPaymentForm')

  if(pesoPayPaymentForm.length > 0) {
    setTimeout(() => {
      pesoPayPaymentForm.first().submit()
    }, 3000)
  }
})

$(document).on('turbolinks:load', () => {
  if(typeof(Prism) != 'undefined') {
    Prism.highlightAll()
  }
})

// Order Ransack
$(document).on("turbolinks:load", () => {
  var ordersCreatedAtRange = $('#q_created_at_between_dates')

  // GuardClause
  if(ordersCreatedAtRange.length == 0) {
    return;
  }

  ordersCreatedAtRange.daterangepicker({
    autoUpdateInput: false,
    ranges: {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    },
    alwaysShowCalendars: true
  })

  ordersCreatedAtRange.on('apply.daterangepicker', (ev, picker) => {
    ordersCreatedAtRange.val(
      picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY')
    )
  })
})

$(document).on("turbolinks:load", () => {
  $('#pal_charter_registration_full_birth_date').daterangepicker({
    timePicker: false,
    singleDatePicker: true,
    locale: {
      format: 'YYYY-MM-DD'
    }
  })
})

$(document).on('turbolinks:load', () => {
  $('#batch_covid_test_specimen_collected_at').daterangepicker({
      timePicker: true,
      singleDatePicker: true,
      locale: {
        format: 'YYYY-MM-DD HH:mm'
      }
  })
})
